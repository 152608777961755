/* eslint-disable */
import React from "react"
import Title from "../../Title"
import styles from "../../../css/email.module.css"
import Keys from '../../../constants/keys'
import Utils from "../../../utils"
import AniLink from "gatsby-plugin-transition-link/AniLink"

class PasswordReset extends React.Component {
  constructor() {
    super();
    this.state = {message: "", reset_id: "", done: false, email: "", reset_secret: "", password: "", confirm_password: ""}
  }

  render() {
    const handleChange = (e) => {
      let state = {};
      state[e.target.name] = e.target.value;
      this.setState(state)
    }
    const handleEmailSubmit = (e) => {
      e.preventDefault();
      let target = e.target;
      window.grecaptcha.execute(Keys.recaptcha_site_key, {action: 'password_reset'}).then((recaptchaToken) => {
        return fetch(`/api/reset`, {
          method: "POST",
          body: JSON.stringify({
            "recaptcha_token": recaptchaToken,
            "email": target.elements.email.value,
          }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Utils.getCookie('csrftoken'),
          }
        })
      }).then(function(response) { return response.json() }).then((response) => {
        target.elements.email.blur();
        this.setState({reset_id: response.reset_id})
      })
    }
    const handleActivationSubmit = (e) => {
      e.preventDefault();
      let target = e.target;
      if(target.elements.reset_secret.value.length != 8) {
        t.setState({message: "Invalid activation code"})
        return
      } else if (target.elements.password.value != target.elements.confirm_password.value) {
        t.setState({message: "Passwords do not match"})
        return
      }
      window.grecaptcha.execute(Keys.recaptcha_site_key, {action: 'reset_finalize'}).then((recaptchaToken) => {
        return fetch(`/api/reset/${this.state.reset_id}`, {
          method: "POST",
          body: JSON.stringify({
            "recaptcha_token": recaptchaToken,
            "reset_secret": target.elements.reset_secret.value,
            "password": target.elements.password.value,
          }),
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": Utils.getCookie('csrftoken'),
          }
        })
      }).then(function(response) { return response.json() }).then((response) => {
        if(response.ok) {
          this.setState({done: true})
        } else {
          if(response.error == "bad-secret") {
            this.setState({message: "Invalid Activation Code"})
          } else {
            this.setState({message: "An unknown error has occurred"})
          }
        }
      })
    }
    if(this.state.reset_id == "") {
      return (
        <section key="email_input" className={styles.email}>
        <span className={styles.modalHeader}><Title title="Complete this form." /></span>
        <p className={styles.instructionsModal}>Enter your email address to initiate a password reset</p>
        <div className={styles.center}>
        <form className={styles.form} onSubmit={handleEmailSubmit}>
        <div>
        <input
        type="email"
        name="email"
        id="email"
        placeholder="email"
        value={this.state.email}
        onChange={handleChange}
        className={styles.formControl}
        />
        </div>
        <div className={styles.arrowButton}>
        <input
        type="submit"
        value="submit"
        className={styles.submit}
        />
        </div>
        <div>{this.state.message}</div>
        </form>
        </div>
        </section>
      )
    } else {
      if(!this.state.done) {
        return (
          <section key="activation_input" className={styles.email}>
          <span className={styles.modalHeader}><Title title="Complete this form." /></span>
          <p className={styles.instructionsModal}>Enter the activation code from your email</p>
          <div className={styles.center}>
          <form className={styles.form} onSubmit={handleActivationSubmit}>
          <div>
          <input
          type="text"
          name="reset_secret"
          id="reset_secret"
          placeholder="activation code"
          value={this.state.reset_secret}
          onChange={handleChange}
          className={styles.formControl}
          />
          </div>
          <div>
          <input
          type="password"
          name="password"
          id="password"
          value={this.state.password}
          onChange={handleChange}
          placeholder="new password"
          className={styles.formControl}
          />
          </div>
          <div>
          <input
          type="password"
          id="confirm_password"
          name="confirm_password"
          placeholder="confirm password"
          value={this.state.confirm_password}
          onChange={handleChange}
          className={styles.formControl}
          />
          </div>
          <div className={styles.arrowButton}>
          <input
          type="submit"
          value="submit"
          className={styles.submit}
          />
          </div>
          <div>{this.state.message}</div>
          </form>
          </div>
          </section>
        )
      } else {
        return (
          <section key="complete" className={styles.email}>
          <span className={styles.modalHeader}><Title title="Password Reset Complete" /></span>
          <div className={styles.center}>
          <center><AniLink className="btn-white" fade to="/login/">Login</AniLink></center>
          </div>
          </section>
        )
      }
    }
  }
}

export default PasswordReset
