/* eslint-disable */
import React, { Component } from "react"
import Layout from "../components/Layout"
import LoadRecaptcha from "../components/LoadRecaptcha"
import StyledHero from "../components/StyledHero"
import PasswordReset from "../components/Home/Signup/PasswordReset"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
export default class rescue extends Component {
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Rivet | Password Reset</title>
        </Helmet>
        <LoadRecaptcha />
        <StyledHero img="none">
        <PasswordReset />
        </StyledHero>
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    defaultBcg: file(relativePath: { eq: "defaultBcg.jpeg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
